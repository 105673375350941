import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import {
  getSeriesURL,
  LearningSeriesWithModulesType,
} from '../../../../graphql/learning'
import { SubscriptionCardIcon } from '../../../../ui/cards/SubscriptionCardIcon'
import FormattedDate from '../../../CMS/FormattedDate'
import { hasFreeAccess } from '../../../Subscriptions/calculatePrices'
import { SubscribeButton } from '../../../Subscriptions/cart/SubscribeButton'
import { getActiveSub } from '../../../Subscriptions/getActiveSub'
import { SubscriptionType } from '../../../Subscriptions/types'
import { getAccreditorShortLabel } from '@/components/Forms/AccreditorPicker'

interface Props {
  series: LearningSeriesWithModulesType
  commonRowData: Record<string, any>
  isFeed?: boolean
  isPreview?: boolean
}

const SeriesRowCard = ({ series, commonRowData, isFeed, isPreview }: Props) => {
  const { t } = useTranslation('learning')
  const router = useRouter()

  const count = series?.module_count?.aggregate?.count ?? 0
  const completedCount = series?.completed_count?.aggregate?.count ?? 0

  // Subscription vars
  const {
    payments_enabled,
    subscription_settings: subscriptionSettings,
    applicable_discount: discount,
    has_subscription: hasSubscription,
  } = series

  const isSubscription = payments_enabled && !!subscriptionSettings
  const availableAsSeries =
    isSubscription && subscriptionSettings?.available_as_series
  const freeAccess = hasFreeAccess(discount?.discount_type)

  const activeSub = getActiveSub(series, null)

  const onItemClick = isPreview
    ? null
    : () => void router.push(getSeriesURL(series))

  const linkTo = isPreview ? null : getSeriesURL(series)

  return (
    <div
      className={
        isFeed
          ? 'fs-unmask'
          : 'fs-unmask layout__item u-1-of-4 u-1-of-2-palm-landscape u-1-of-1-palm-portrait u-1-of-3-lap u-1-of-4-desk u-1-of-6-large-desk'
      }
    >
      <div
        className="card learning-card__wrapper"
        style={isFeed ? { marginLeft: 0 } : null}
      >
        <div className="learning-card__resource" style={{ width: '100%' }}>
          <Link href={linkTo}>
            <div
              style={{
                backgroundImage: `url(${commonRowData.coverImage})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                height: '100%',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
              }}
            >
              <span className="learning-card__label series">Series</span>
              {series.accreditation_when === 'series' && (
                <span
                  className="learning-card__label"
                  style={{ top: '3.2rem', backgroundColor: '#58368a' }}
                >
                  {getAccreditorShortLabel(series.cme_accreditor)}
                </span>
              )}

              <SubscriptionCardIcon series={series} cardType="series" />
            </div>
          </Link>
        </div>

        <Link href={linkTo}>
          <div style={{ height: '217px' }}>
            <div className="learning-card__title-section">
              <div className="learning-card__title">
                <span title={series.title}>
                  {series.title.length < 67
                    ? series.title
                    : series.title.substring(0, 67).trim() + '...'}
                </span>
              </div>
            </div>

            {/* Author */}
            <div className="learning-card__row" style={{ height: '20px' }}>
              {series?.display_author && (
                <span title={series?.display_author}>
                  <strong>Author:</strong> {series?.display_author}
                </span>
              )}
            </div>

            <div className="learning-card__row" style={{ height: '30px' }} />

            {/* Subscription Details */}
            <div
              className="learning-card__row"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '40px',
              }}
            >
              {isSubscription && activeSub ? (
                <>
                  <div>
                    <div style={{ fontWeight: 700 }}>Subscribed</div>
                    {activeSub?.created_at ? (
                      <FormattedDate
                        date={activeSub.created_at}
                        format="short"
                      />
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <div style={{ fontWeight: 700 }}>Expires</div>
                    {activeSub?.expires_at &&
                    activeSub.expires_at < '9999-01-01' ? (
                      <FormattedDate
                        date={activeSub.expires_at}
                        format="short"
                      />
                    ) : (
                      'N/A'
                    )}
                  </div>
                </>
              ) : (
                <>
                  {series.library?.title && (
                    <span className="" title={series.library.title}>
                      <strong>Group:</strong>{' '}
                      {series.library.title.length < 50
                        ? series.library.title
                        : series.library.title.substring(0, 45).trim() + '...'}
                    </span>
                  )}
                </>
              )}
            </div>

            {/* Module Count */}
            <div
              className="learning-card__footer"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-mortarboard"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
                  <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
                </svg>
                <span style={{ marginLeft: '3px' }}>
                  {t('learning:learning.series_card_modules_count', {
                    count: count,
                  })}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-award"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                </svg>
                <span style={{ marginLeft: '3px' }}>
                  {completedCount}/{count}
                </span>
              </div>
            </div>
          </div>
        </Link>

        <div className="learning-card__buttons" style={{ marginTop: '14px' }}>
          {isSubscription && !activeSub && !freeAccess && (
            <div>
              <SubscribeButton
                productID={series.id}
                type={SubscriptionType.SERIES}
                seriesID={series.id}
                libraryID={series.library_id}
                availableAsSeries={availableAsSeries}
                label={
                  subscriptionSettings.subscription_interval
                    ? 'Subscribe'
                    : 'Buy now'
                }
              />
            </div>
          )}

          <button
            className="btn"
            style={
              isSubscription && !activeSub && !freeAccess
                ? null
                : { width: '100%' }
            }
            onClick={onItemClick}
          >
            {isSubscription && !activeSub && !freeAccess
              ? 'Details'
              : 'View Details'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SeriesRowCard
