export const getAccreditorOptions = () => {
  return [
    ['CPD UK', 'CPD UK'],
    ['EACCME®', 'EACCME®'],
    ['ACCME®', 'ACCME®'],
    ['ASRT', 'ASRT'],
    ['FPM', 'Faculty of Pharmaceutical Medicine'],
  ]
}

export const getAccreditorLabel = (accreditor: string) => {
  return getAccreditorOptions().find(([value]) => value === accreditor)?.[1]
}

export const getAccreditorShortLabel = (accreditor: string) => {
  const label = getAccreditorLabel(accreditor)
  if (label?.length < 10) {
    return label
  }
  return 'CPD'
}

export const getAccreditorLogo = (accreditor: string) => {
  return {
    'CPD UK': '/static/media/images/cpd-logo.png',
    'EACCME®': '/static/media/images/eaccme-logo.png',
    'ACCME®': '/static/media/images/accme-logo.png',
    ASRT: '/static/media/images/asrt-logo.png',
    FPM: '/static/media/images/fpm-logo.png',
  }[accreditor]
}

export const AccreditorPicker = ({
  id,
  name,
  defaultValue,
  onChange,
}: {
  id: string
  name: string
  defaultValue: string
  onChange?: (
    e: React.ChangeEvent<HTMLSelectElement>,
    info: { name: string; value: string }
  ) => void
}) => {
  return (
    <select
      defaultValue={defaultValue}
      onChange={
        onChange
          ? e => onChange(e, { name: name, value: e.target.value })
          : undefined
      }
      name={name}
      id={id}
    >
      <option value="any">Any</option>
      {getAccreditorOptions().map(([value, label]) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
      <option value="none">Not Accredited</option>
    </select>
  )
}
